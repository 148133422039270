@font-face {
    font-family: ClashDisplay;
    font-display: block;
    src: local('ClashDisplay'), url('./fonts/ClashDisplay-Regular.woff2') format('woff2');
}

@font-face {
    font-family: ClashDisplayBold;
    font-display: block;
    src: local('ClashDisplayBold'), url('./fonts/ClashDisplay-Bold.woff2') format('woff2');
}

/* @font-face {
    font-family: MortendBold;
    src: url('./fonts/Mortend-Bold.ttf');
} */

/* @font-face {
    font-family: PoppinsBold;
    src: url('./fonts/Poppins-Bold.ttf');
} */

/* @font-face {
    font-family: PoppinsRegular;
    src: url('./fonts/Poppins-Regular.ttf');
} */

:root {
    --footer-item-color: rgb(125,125,125);
    --ss-orange: rgb(233,161,69);
    --fontSize-small: 14px;
}

body {
    font-family: ClashDisplay;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    height: 100%;
    overscroll-behavior-y: contain;
}

html {
    background-color: rgb(17, 17, 17);
    width: fit-content;
    min-width: 100%;
    max-height: fit-content;
    color: rgb(252,252,252);
}