.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: fit-content;
    margin: auto 15px;
    padding-bottom: 20px;
    gap: 6px;
}

.whitelist {
    display: inline-block;
    min-height: 10px;
    min-width: 10px;
    height: 34px;
    width: 34px;
}

.empty {
    background-image: url("../imgs/empty-desktop.png");
    background-size: cover;
    height: 100%;
}

.filled {
    /* background-image: linear-gradient(to top,rgb(233,230,69),rgb(233,161,69),rgb(206,136,44),rgb(174,100,57)); */
    background-image: url("../imgs/burned-desktop.png");
    background-size: cover;
    height: 100%;
}

.filled:hover {
    background-image: url("../imgs/hover-desktop.png");
    background-size: cover;
}

.spot_name {
    display: none;
}

.filled[name]:hover:after {
    color: rgb(222,222,222);
    content: attr(name);
    font-size: small;
    letter-spacing: 0.04em;
    border: 1px solid rgb(65,65,65);
    border-radius: 8px;
    background-color: rgba(0,0,0,.8);
    position: absolute;
    padding: 10px 12px;
    transform: translate(-50%, -100%);
}

@media only screen and (max-width: 1200px) and (max-height: 1000px) {

    .whitelist {
        height: 2vw;
        width: 2vw;
    }

    .grid {
        margin: 0 14px;
        gap: 4px;
    }
}

@media only screen and (max-width: 680px) {

    .whitelist {
        height: 5.3vw;
        width: 5.3vw;
    }
}

@media only screen and (max-width: 350px) {

    .grid {
        gap: 3px;
    }
}