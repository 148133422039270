.center {
    display: flex;
    margin: auto;
}

.icon {
    height: 18px;
}

.left {
    display: flex;
    column-gap: 18px;
    font-weight: bolder;
    width: 250px;
}

.left a {
    font-weight: bold;
    color: var(--footer-item-color); /*rgb(78,78,78);*/ /* rgb(67,67,67) */
}

.left a:hover {
    color: var(--ss-orange);
    filter: drop-shadow(-2px 2px 2px rgb(185,37,4));
}

.right {
    display: flex;
    justify-content: flex-end;
    column-gap: 18px;
    width: 250px;
}

.right a {
    display: inline-flex;
    height: 24px;
    align-items: center;
    vertical-align: middle;
    /* align-self: center; */
    /* justify-self: center; */
}

.row {
    display: flex;
    align-items: center;
    width: 100%; 
}

svg.icon {
    fill: var(--footer-item-color);
}

.footer {
    height: 72px;
    color: var(--footer-item-color);
    min-width: 260px;
    max-width: 100vw;
    display: flex;
    letter-spacing: .04em;
    margin: auto 2.1vw;
}

.footer svg:hover {
    fill: var(--ss-orange);
    filter: drop-shadow(-2px 2px 2px rgb(185,37,4));
}

@media (max-width: 680px) {
  
    .display {
        display: none;
    }

    .footer {
        margin: auto 5vw;
    }
    
    .right {
        column-gap: 24px;
    }
}

@media (max-width: 480px) {
  
    .footer {
        font-size: var(--fontSize-small);
    }

    .footer .logo {
        height: 24px;
    }

    .icon {
        height: 18px;
    }
    
    .left {
        width: 150px;
        column-gap: 12px;
    }

    .right {
        width: 150px;
    }
}

@media only screen and (max-width: 350px) {

    .footer {
        font-size: 12px;
        margin: auto 6vw;
    }

    .footer .logo {
        height: 20px;
    }

    .left {
        width: 100px;
        column-gap: 8px;
    }

    .right {
        width: 100px;
    }
    
    .icon {
        height: 16px;
    }
}