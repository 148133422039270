.Content {
    align-items: center;
    min-width: 300px;
    background-color: black;
    background-image: url("../imgs/anime-style-galaxy-background 1.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.logo {
    display: flex;
    height: 34px;
    margin: auto;
}

.border-top {
    display: flex;
    height: 16px;
    margin: -1px -1px auto;
}

.border-left {
    background-image: url('../imgs/border-top-left.svg');
    background-repeat: no-repeat;
    margin-right: auto;
    width: 78px;
}

.border-right {
    background-image: url('../imgs/border-top-right.svg'); 
    background-repeat: no-repeat;
    width: 78px;
}

.border-bottom {
    background-image: url('../imgs/border-bottom.svg');
    background-repeat: no-repeat;
    background-size: contain;
    height: 19px;
    width: 180px;
    margin-bottom: -2px;
}

.heading {
    font-family: ClashDisplayBold;
    color: white;
    display: flex;
    font-size: 48px;
    width: fit-content;
    min-width: 250px;
    padding: 25px;
    margin: auto;
}

.heading .logo {
    height: 33px;
}

.subheading {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: .09em;
}

.burn_num {
    color: var(--ss-orange);
    text-shadow: 0px 0px 8px var(--ss-orange);
}

.container {
    width: 100%;
    min-width: 280px;
    display: flex;
    justify-content: center;
    gap: 1.8vw;
}

.title_box {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.09em;
    width: 100%;
    align-items: center;
    font-weight: bold;
    margin: 4px 0 auto;
    border-bottom: 2px solid var(--ss-orange);
}

.description_text {
    margin: auto 25px;
    text-align: center;
}

.content_panel {
    display: flex;
    min-width: fit-content; /* 260px */
    text-align: center;
    padding-top: 20px;
    /* height: 66.4vh; 67 */
}

.wl_box {
    border: 2px solid var(--ss-orange);
    background-color: rgba(0,0,0,.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin-bottom: 30px;
    min-width: 280px;
    width: 47vw;
}

#wl_spots {
    height: fit-content;
}

#wl_names {
    overflow-y: scroll;
    overscroll-behavior: contain;
    margin-right: 13px;
    padding-left: 30px;
    padding-right: 14px;
}

.Content ::-webkit-scrollbar {
    width: 5px;
}

.Content ::-webkit-scrollbar-track {
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Content ::-webkit-scrollbar-thumb {
    background: var(--ss-orange);
    border-radius: 6px;
}

@media only screen and (max-width: 680px) {

    .container {
        gap: 0;
        flex-direction: column;
        align-items: center;
    }

    .heading {
        font-size: 36px;
        padding: 25px 20px;
    }

    .wl_box {
        width: 90vw;
    }
    
    .heading .logo {
        height: 25px;
        padding-bottom: 1px;
    }
}
 
@media only screen and (max-width: 480px) {

    .heading {
        font-size: 24px;
    }

    .heading .logo {
        height: 18px;
    }
}

@media only screen and (max-width: 350px) {

    .heading {
        font-size: 22px;
    }

    .heading .logo {
        height: 15px;
    }

    .description_text {
        font-size: var(--fontSize-small);
    }

    .subheading {
        font-size: var(--fontSize-small);
    }

    .wl_box {
        width: 88vw;
    }
}