.whitelist_name {
    display: flex;
    align-items: center;
    letter-spacing: .1em;
    font-size: 16px;
    font-weight: bold;
    padding: 16px 18px;
    border-radius: 12px;
    border: 1px solid rgba(233,161,69,.4);
    background-color: rgba(0,0,0,.2);
    color: rgb(252,252,252);
    box-shadow:2px 3px 4px rgba(0,0,0,.2), inset 0 0 20px rgba(0,0,0,.1);
}

.whitelist_name:hover {
    background-color: rgba(233,161,69,.3);
    border: 1px solid var(--ss-orange);
}

.name a[title]:hover:after {
    color: rgb(222,222,222);
    content: "View profile";
    font-size: small;
    letter-spacing: 0.04em;
    border: 1px solid rgb(65,65,65);
    border-radius: 8px;
    background-color: rgba(0,0,0,.8);
    position: absolute;
    padding: 10px 12px;
    transform: translate(-50%, -225%);
}

.name:first-of-type a[title]:hover:after, .name:nth-child(2) a[title]:hover:after {
    transform: translate(-50%, -208%);
}

.names {
    display: inline-grid;
    column-gap: 18px;
    row-gap: 14px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.name:last-of-type {
    padding-bottom: 20px;
}

.x_icon {
    border-radius: 5px;
    background-image: linear-gradient(to bottom,rgb(233,230,69),rgb(233,161,69),rgb(206,136,44),rgb(174,100,57));
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}

@media only screen and (max-width: 1200px) {

    .names {
        grid-template-columns: 1fr;
        row-gap: 8px;
    }
}

@media only screen and (max-width: 350px) {

    .whitelist_name {
        font-size: var(--fontSize-small);
    }
}